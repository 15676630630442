<template>
    <div class="message-panel" v-loading="loading">
        <div class="white-bg">
            <h3 class="g-title" style="margin-top: -20px;">消息详情</h3>
            <div class="msg-detail-panel">
                <div class="msg-detail-hd">
                    <h4 class="name">{{message.title}}</h4>
                    <p>
                        发信人：
                        <span>
                            <strong>{{message._sender?message._sender.geek_id:''}}</strong>
                            <{{message._sender?message._sender.name:''}}>
                        </span>
                    </p>
                    <p>
                        收信人：
                        <span>
                            <strong>{{message._receiver?message._receiver.geek_id:''}}</strong>
                            <{{message._receiver?message._receiver.name:''}}>
                        </span>
                    </p>
                    <!-- <p v-if="message.user_type == 2">
                    收信人：
                    <span>
                        <strong>{{message._receiver.geek_id}}</strong> <工程极客>
                    </span>
                </p>
                <p v-if="message.user_type == 1">
                    收信人：
                    <span>
                        <strong>{{message._receiver.geek_id}}</strong> <雇主>
                    </span>
                    </p>-->
                    <p>
                        时间：
                        <span>{{message.send_time}}</span>
                    </p>
                </div>
                <div class="msg-detail-bd">
                    <p v-html="message.content"></p>
                </div>
                <div class="msg-detail-ftr">
                    <el-button @click="returnPage">返回</el-button>
                    <el-button @click="goReply" v-if="message.type == 'receiver'">回复</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "detail",
    metaInfo() {
        return {
            title: "消息详情 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {},
    data() {
        return {
            message: [],
            loading: false
        };
    },
    methods: {
        //标记消息图标为已读
        ...mapActions("userInfo", ["ajaxUserInfo"]),
        //返回
        returnPage() {
            this.$router.push(
                "/user/message?id=" +
                sessionStorage.getItem("messageActive") +
                "&page=" +
                sessionStorage.getItem("messagePage")
            );
        },

        goReply() {
            this.$router.push("/user/message/reply?id=" + this.message.id);
        },

        getOne() {
            let _this = this;
            _this.post(
                "/message/message/detail",
                {
                    id: _this.$route.query.id
                },
                data => {
                    if (data.code == 200) {
                        this.loading = false;
                        _this.message = data.data;
                        _this.ajaxUserInfo(); //刷新用户数据
                    } else {
                        //如果消息未支付
                        _this.$message.error(data.msg);
                        setTimeout(() => {
                            this.loading = false;
                            this.$router.push("/user/message?id=3"); //返回到未读列表
                        }, 1000);
                    }
                }
            );
        }
    },
    created() {
        this.loading = true;
        this.getOne();
    }
};
</script>
<style lang="less">
@import "../../../styles/message.less";
.msg-detail-ftr {
    .el-button {
        border-radius: 3px;
        transition: all ease 0.4s;
        &:hover {
            opacity: 0.9;
            border-color: rgba(88, 125, 220, 0.6);
        }
    }
}
</style>


